@import url('https://fonts.googleapis.com/css2?family=Trispace:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One&display=swap');

$color-brown: #84614f;
$color-sand: #dda479;
$color-pink: #f5cab7;
$color-grey-light: #ddd;
$color-grey-dark: #888;
$breakpoint-small: 769px;
$breakpoint-medium: 1024px;
$breakpoint-large: 1366px;

html {
    scroll-behavior: smooth;
}

body {
    background-image: url('./Images/fyv_hero.avif');
    background-attachment: fixed;
    background-size: 100% auto;
    color: $color-grey-dark;

    @media only screen and (max-width: $breakpoint-small) {
        background-position-y: 6vh;
    }

    h1 {
        font-family: 'Figtree', sans-serif;
        margin-block-start: 0;
        margin-block-end: 0;
        color: $color-sand;
        font-size: 4vmin;

        @media only screen and (max-width: $breakpoint-small) {
            padding-top: 3vh;
            font-size: 7vmin;
        }
    }

    h2 {
        font-family: 'Figtree', sans-serif;
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 4vmin;
        color: $color-brown;
    }

    h3 {
        font-family: 'Trispace', sans-serif;
        font-size: 1.5rem;
        font-style: italic;
        font-weight: 100;
        color: #84614f;
        margin-block-start: 0;
        margin-bottom: 0;
    }

    h4,
    aside {
        font-family: 'Figtree', sans-serif;
        margin-top: 4vmin;
        margin-bottom: 2vmin;
        font-size: 2.5vmin;
        color: $color-sand;
        font-weight: bold;
        span {
            font-style: italic;
            text-decoration: underline;
        }
        @media only screen and (max-width: $breakpoint-small) {
            margin-top: 6vmin;
            font-size: 4.5vmin;
        }
    }

    h5 {
        font-family: 'Passion One', cursive;
        right: 0;
        margin: 0;
        margin-right: 11.5vw;
        margin-top: -10vh;
        font-size: 40vh;
        font-weight: 400;
        color: rgba($color-pink, 0.4);
        position: absolute;
        z-index: 0;
    }

    p,
    blockquote {
        margin-bottom: 10px;
        font-family: 'Trispace', sans-serif;
        @media only screen and (max-width: $breakpoint-small) {
            font-size: 4vmin;
        }
    }

    blockquote {
        margin: 2rem 0;
        margin-bottom: 1rem;
    }

    cite {
        font-family: 'Figtree', sans-serif;
        margin-top: 4vmin;
        margin-bottom: 2vmin;
        font-size: 2.5vmin;
        color: $color-sand;
        font-weight: bold;
    }

    span {
        color: $color-sand;
        font-weight: bold;
    }

    a {
        color: $color-brown;
    }
}

.navbar {
    z-index: 3;
    background-color: $color-brown;
    color: $color-pink;
    height: 8vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    font-family: 'Figtree', sans-serif;
    letter-spacing: 0.3rem;

    &--expanded {
        height: 100vh;
        flex-direction: column;
        justify-content: space-around;
    }

    &__logo {
        position: relative;

        &--small {
            height: 4.5vmin;
            margin: 2vmin;
            @media (max-width: $breakpoint-small) {
                height: 7vmin;
            }
        }

        &--large {
            height: 20vh;
            margin: 20vh;
        }
    }

    &__button {
        display: none;
        position: relative;
        height: 5vw;
        width: 5vw;
        max-height: 90%;
        padding: 3vw;

        svg {
            fill: $color-pink;
        }

        @media only screen and (max-width: $breakpoint-large) {
            display: flex;
        }

        &--expanded {
            position: fixed;
            top: 0;
            right: 0;
            padding: 3vw;
        }
    }

    &__routes {
        width: auto;
        display: flex;
        align-items: center;

        @media only screen and (max-width: $breakpoint-large) {
            display: none;
        }

        &--expanded {
            display: inline-flex;
            flex-direction: column;
            height: 30%;
            justify-content: space-around;
            margin-bottom: 10vh;
        }
    }

    &__route {
        &--active {
            color: white !important;
        }

        @media only screen and (max-width: $breakpoint-large) {
            font-size: 3vmin;
        }

        @media only screen and (max-width: $breakpoint-small) {
            font-size: 5vmin;
        }
    }

    a {
        white-space: nowrap;
        text-decoration: none;
        color: $color-pink;
        margin: 1vw;
        display: flex;
        align-items: center;

        &:hover {
            color: white;
        }
    }
}

.header {
    align-content: center;
    height: 90vmin;
    @media (max-width: $breakpoint-medium) {
        height: 70vmin;
    }
    img {
        top: 0px;
        position: relative;
        width: 100%;
        margin-top: 8vh;
        margin-bottom: 2vh;
        max-width: 100%;
        height: auto;
    }
}

.section {
    &__container {
        display: grid;
        gap: 2vw;
        grid-template-columns: auto auto auto;
        padding: 5vw 11.5vw;
        @media only screen and (max-width: $breakpoint-small) {
            grid-template-columns: auto;
            justify-items: center;
        }
        &--advent {
            grid-template-columns: auto auto auto auto;
            padding-top: 3vw;
            @media only screen and (max-width: $breakpoint-small) {
                grid-template-columns: auto;
                justify-items: center;
            }
        }
        &--header {
            gap: 0;
            padding: 4vw 11.5vw;
        }
    }

    &__title {
        text-align: left;
        padding-top: 6vw;
        padding-bottom: 1vw;
        border-bottom: 0.4vh solid rgba($color-sand, 0.5);
        margin-left: 11.5vw;
    }

    &__description {
        p {
            text-align: center;
            margin-block-end: 0;
            margin-top: 5vmin;
            @media only screen and (min-width: $breakpoint-large) {
                font-size: 2.5vmin;
            }
        }
    }

    &__img {
        max-width: 24vw;
        height: auto;
        transition: box-shadow 100ms;
        box-shadow: 1vh 1vh white;

        &--advent {
            min-width: 18vw;
            min-height: 18vw;
            max-width: 18vw;
            max-height: 18vw;
            object-fit: cover;

            @media only screen and (max-width: $breakpoint-small) {
                margin: 3vw;
                min-width: 65vw;
                min-height: 65vw;
                max-width: 65vw;
                max-height: 65vw;
            }
        }

        &:hover {
            cursor: pointer;
            box-shadow: 1vh 1vh $color-grey-light;
        }

        @media only screen and (max-width: $breakpoint-small) {
            margin: 3vw;
            max-width: 65vw;
        }
    }
}

.modal {
    display: flex;
    justify-content: center;

    &__bckg {
        position: fixed;
        z-index: 4;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);
    }

    &__button {
        position: fixed;
        top: 40vh;
        height: 3vw;
        width: 3vw;
        margin: 2vw;
        z-index: 6;
        background-color: $color-pink;
        border-radius: 100%;
        fill: $color-brown;
        cursor: pointer;
        border: none;
        padding: 0;
        line-height: 0rem;

        &--previous {
            left: 0;
        }

        &--next {
            right: 0;
        }

        @media only screen and (max-width: $breakpoint-small) {
            margin: 4vw;
            height: 10vw;
            width: 10vw;
        }
    }

    &__box {
        position: fixed;
        z-index: 5;
        top: 8vh;
        overflow: auto;
        display: block;
        padding: 1vw 2vw 4vw 2vw;
        width: 75vw;
        height: auto;
        background-color: white;

        @media only screen and (max-width: $breakpoint-small) {
            display: block;
            width: 82vw;
            height: 87vh;
            padding: 0;
            overflow: auto;
        }

        &--advent {
            width: 60vw;
            height: 80vh;
            @media only screen and (max-width: $breakpoint-small) {
                width: 80vw;
                height: 85vh;
            }
        }
    }

    &__content {
        display: flex;
        padding: 1.3rem;
        height: 60svh;

        @media only screen and (max-width: $breakpoint-small) {
            flex-direction: column;
            padding: 0;
            height: 78vh;
            border-bottom: 2px solid $color-pink;
        }
    }

    &__text {
        overflow: auto;
        padding: 0rem 2rem;
        color: $color-grey-dark;
        word-wrap: break-word;

        p {
            margin-block-start: 0px;
            margin-bottom: 3vmin;
        }

        h4 {
            margin-block-start: 0px;
        }

        @media only screen and (max-width: $breakpoint-small) {
            box-shadow: 0 -10px 10px rgba($color-pink, 0.2) inset;
            padding: 9vw;
            overflow: auto;
        }
        &--advent {
            padding-right: 2rem;

            @media only screen and (min-width: $breakpoint-small) {
                font-size: 3vmin;
                h4 {
                    font-size: 3.2vmin;
                }
            }
        }
    }

    &__img {
        display: block;
        text-align: center;
        min-width: 45%;

        img {
            width: 100%;
            height: 60vh;
            object-fit: contain;

            @media only screen and (max-width: $breakpoint-small) {
                height: 30vh;
            }
        }

        &--advent {
            img {
                width: 100%;
                height: 70vh;
                @media only screen and (max-width: $breakpoint-large) {
                    width: 30vh;
                    height: 30vh;
                    object-fit: cover;
                }
            }
        }
    }

    &__close {
        text-align: right;
        margin-right: 1rem;
        margin-top: 1rem;

        button {
            color: $color-sand;
            font-size: 2rem;
            font-weight: bold;
            &:hover {
                cursor: pointer;
                color: $color-brown;
                text-decoration: none;
            }
        }
    }

    &__follow {
        vertical-align: bottom;
        text-align: right;
        margin-right: 1rem;
        margin-top: 1rem;
        display: flex;
        align-items: baseline;
        justify-content: flex-end;

        @media only screen and (max-width: $breakpoint-small) {
            justify-content: space-evenly;
        }

        a {
            font-family: 'Trispace', sans-serif;
            text-decoration: none;
            font-weight: bold;
            color: $color-sand;
            display: flex;
            align-items: top;

            &:hover {
                color: $color-brown;
            }
        }

        img {
            height: 2vh;
            margin: 1px 5px 0px 20px;
        }
    }

    &__carousel {
        border-radius: 100%;
        padding: 0.3rem;
        margin: 0.3rem;
        border: none;
        background-color: $color-grey-light;

        &:hover {
            cursor: pointer;
            background-color: $color-sand;
        }

        &--selected {
            background-color: $color-pink;
        }
    }
}

.bodywork {
    padding: 5vw 11.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: rgba(white, 0.5);
    p {
        margin-top: 0;
        margin-bottom: 1.5rem;
    }

    div {
        @media only screen and (max-width: $breakpoint-small) {
            width: 65vw;
        }
    }

    @media only screen and (max-width: $breakpoint-small) {
        flex-direction: column;
        align-items: center;
    }

    h2 {
        font-size: calc(2rem + 4vmin);
        margin-bottom: 6vmin;
        margin-top: -2vmin;
        color: $color-sand;
    }

    h3 {
        font-size: 1.5rem;
        font-style: italic;
        font-weight: 100;
        color: $color-brown;
        margin-block-start: 0;
        margin-bottom: 1rem;
    }

    h4 {
        margin-block-start: 0;
        margin-bottom: 3rem;
        font-size: calc(2rem + 4vmin);
        font-weight: 100;
        color: $color-brown;
    }

    &__boxArea {
        padding: 5vw 7.5vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: rgba(white, 0.7);
        border-bottom: 0.4vh solid rgba($color-sand, 0.5);
        margin: 3vh 0 1vh 0;
    }

    &__col-narrow {
        width: 20vw;

        @media only screen and (max-width: $breakpoint-small) {
            width: 65vw;
        }
    }

    &__col {
        width: 24.5vw;
        @media only screen and (max-width: $breakpoint-small) {
            width: 65vw;
        }
    }

    &__col-grid-2 {
        column-count: 2;
        column-gap: 6vw;
        @media only screen and (max-width: $breakpoint-small) {
            width: 65vw;
            column-count: 1;
        }
    }
}

.about {
    padding: 5vw 11.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: rgba(white, 0.5);
    p {
        margin-top: 0;
        margin-bottom: 3vh;
    }

    @media only screen and (max-width: $breakpoint-small) {
        flex-direction: column;
        align-items: center;
    }

    &__text {
        width: 24.5vw;
        @media only screen and (max-width: $breakpoint-small) {
            width: 65vw;
        }
    }

    &__photo {
        width: 19vw;

        @media only screen and (max-width: $breakpoint-small) {
            width: 65vw;
        }

        img {
            width: 18vw;
            height: 18vw;
            margin-bottom: 4vh;
            object-fit: cover;
            @media only screen and (max-width: $breakpoint-small) {
                width: 65vw;
                height: 65vw;
                margin-top: 4vh;
            }
        }
    }
}

.footer {
    position: relative;
    background-color: $color-pink;
    color: $color-brown;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8vh;
    padding: 4vh 9vw;

    @media only screen and (max-width: $breakpoint-small) {
        flex-direction: column;
    }

    div {
        display: flex;
        align-items: center;

        @media only screen and (max-width: $breakpoint-small) {
            flex-direction: column;
        }
    }

    a {
        font-family: 'Trispace', sans-serif;
        text-decoration: none;
        color: $color-brown;
        display: flex;
        align-items: center;
        margin-right: 3vh;

        &:hover {
            color: $color-sand;
        }

        @media only screen and (max-width: $breakpoint-small) {
            flex-direction: column;
            margin: 0 0 2vh 0;
        }
    }

    &__contact {
        font-style: italic;
        @media only screen and (max-width: $breakpoint-small) {
            margin-top: 2.5vh;
        }
    }

    &__logo {
        height: 10vh;
        margin: 1vh;
        animation: footer__logo-spin infinite 20s linear;

        &:hover {
            cursor: pointer;
        }
    }

    &__icons {
        height: 3vh;
        margin: 1vh 1vh 1vh 0;
    }
}

@keyframes footer__logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 5px solid $color-sand;
    border-left: 5px solid $color-brown;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    animation: spin 2s linear infinite;
    margin-top: 2vh;
    margin-bottom: 5vh;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.button-reset-styles {
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-family: 'Figtree', sans-serif;
    line-height: 0rem;
    background: transparent;
    -webkit-appearance: none;
}

::-webkit-scrollbar {
    height: 0.3rem;
    width: 0.3rem;
    background-color: $color-grey-light;
}

::-webkit-scrollbar-thumb {
    border-radius: 9999px;
    background-color: $color-sand;
}
